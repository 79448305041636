import PrecoolSchedule from "./PrecoolSchedule.json";

let dataPrecoolSchedule: Array<any> = [];

const getPrecoolSchedule = (emptyData?: boolean, updateData?: boolean) => {
  if (emptyData) dataPrecoolSchedule = [];
  if (updateData) dataPrecoolSchedule = PrecoolSchedule;

  return dataPrecoolSchedule;
};

export { getPrecoolSchedule, PrecoolSchedule };
